import { useFormik } from "formik";
import { AiOutlineInfoCircle } from "react-icons/ai";
import * as yup from "yup";
import Breadcrumb from "../../components/Breadcrumb";
import { useToast } from "../../components/Toast/toastHook";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_NOTIFICATION_AGENTS,
  GET_NOTIFICATION_USERS,
  SEND_NOTIFICATION,
  SEND_NOTIFICATION_OFFICE,
} from "../../graphql/notificationQueries";
import { GET_COMPANY_OFFICES } from "../../graphql/companyQueries";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import CompanyNotificationForm from "../../components/companies/CompanyNotificationForm";

const AdminNotifications = () => {
  const toast = useToast(4000);
  const { companyId } = useParams();
  const [sendNotificationOffice, { data, loading, error }] = useMutation(
    SEND_NOTIFICATION_OFFICE,
    {
      onCompleted(result) {
        toast("success", `Notification send successfully!`);
      },
      onError(e) {
        console.error(e);
        toast("error", e?.message ?? "Something went wrong!");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      loading: false,
      toEveryOne: false,
      search: {
        office: "",
        agent: "",
      },
      isOpen: {
        office: false,
        agent: false,
      },
      selectedItems: { office: [], agent: [] },
      companies: [],
      users: [],
    },
    validationSchema: yup.object().shape({
      title: yup.string().required("Title cannot be empty").max(100),
      description: yup.string().required("Description is Required").max(500),
    }),
    onSubmit: async (values, helper) => {
      formik.setFieldValue("loading", true);
      let notificationOfficeInput = {
        body: values.description,
        title: values.title,
      };
      if (values.selectedItems.office.length > 0) {
        notificationOfficeInput = {
          ...notificationOfficeInput,
          officeIDs: values.selectedItems.office,
        };
      }
      if (values.selectedItems.agent.length > 0) {
        notificationOfficeInput = {
          ...notificationOfficeInput,
          agentIDs: values.selectedItems.agent,
        };
      }
      sendNotificationOffice({
        variables: {
          notificationOfficeInput,
        },
      });
      helper.resetForm();
      formik.setFieldValue("loading", false);
      populateData();
    },
  });
  console.log(formik.values);
  const companyRes = useQuery(GET_COMPANY_OFFICES, {
    variables: {
      companyId: companyId,
    },
  });

  const userRes = useQuery(GET_NOTIFICATION_AGENTS, {
    variables: {
      companyId: companyId,
    },
  });

  const dropdownRef = useRef(null);

  const populateData = () => {
    if (companyRes.data) {
      formik.setFieldValue("companies", companyRes.data.getCompanyOffice);
    }
    if (userRes.data) {
      formik.setFieldValue("users", userRes.data.getNotificationAgents);
    }
  };

  useEffect(() => {
    populateData();
  }, [companyRes.data, userRes.data]);

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        formik.setFieldValue("isOpen.office", false);
        formik.setFieldValue("isOpen.agent", false);
      }
    };
    document.addEventListener("mousedown", handleBodyClick);

    return () => {
      document.removeEventListener("mousedown", handleBodyClick);
    };
  }, []);

  return (
    <div id="Notifications">
      <Breadcrumb pageName="Notifications" />
      <div className="flex gap-2 items-start md:items-center flex-col md:flex-row text-slate-400">
        <AiOutlineInfoCircle size="18" />
        <p className="text-sm">
          Facilitates the broadcasting of notifications to all registered agents
          of the mobile application.
        </p>
      </div>
      <CompanyNotificationForm formik={formik} dropdownRef={dropdownRef} />
    </div>
  );
};

export default AdminNotifications;
