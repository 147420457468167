import { gql } from "@apollo/client";

export const SEND_NOTIFICATION = gql`
  mutation sendNotification($notificationInput: NotificationInput) {
    sendNotification(notificationInput: $notificationInput)
  }
`;

export const SEND_NOTIFICATION_OFFICE = gql`
  mutation sendNotificationOffice(
    $notificationOfficeInput: NotificationOfficeInput
  ) {
    sendNotificationOffice(notificationOfficeInput: $notificationOfficeInput)
  }
`;

export const GET_NOTIFICATION_USERS = gql`
  query {
    getNotificationUsers {
      id
      fullName
    }
  }
`;

export const GET_NOTIFICATION_AGENTS = gql`
  query getNotificationAgents($companyId: String) {
    getNotificationAgents(companyId: $companyId) {
      id
      fullName
    }
  }
`;
